.records-table {
  width: 100%;
  border-collapse: collapse;
}

.records-table th, .records-table td {
  padding: 12px;
  text-align: left;
}

.records-table th {
  background-color: #f4f4f4;
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
}

.export-button {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: #939494;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
}

.export-button:hover {
  background-color: #45a049;
}


  