.registration-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f3eb;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Arial, sans-serif;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 15px;
  }
  
  .form-group:last-child {
    margin-right: 0;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
  }
  
  input[type="checkbox"] {
    margin-top: 8px;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #c8102e;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #a00d24;
  }
  
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
  
    .form-group {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .form-group:last-child {
      margin-bottom: 0;
    }
  }
  